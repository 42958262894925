import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

export default function HelloChanger(props: any) {
    const theme = createTheme();
    const [code, setCode] = React.useState(props.code ? props.code : "hello");
    const { t, i18n } = useTranslation();


    //START Hande inputs and submit
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const hello = new FormData(event.currentTarget).get('hello');
        
        props.changer(hello) //Send to campaign.tsx the new hello code from input. 
    };
    const handleChange = (e:any) => {
        setCode(e.target.value);// Change the state with code from input
    }
    //END Handle inputs


    //BREAKPOINTS for CSS inline MUI properties:
    //mobile: 0,
    //tablet: 599,
    //desktop: 1201,
    //larger: 2500
    // [mobile,tablet,desktop,larger]
        return (
            <ThemeProvider theme={theme}>
                <Container component="main" sx={{
                    width: ['auto', null, '675px']
                }} >
                    <CssBaseline />
                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Typography component="h1" variant="h5">
                            {t("digital.new_hello") }
                        </Typography>
                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                fullWidth
                                id="hello"
                                label={ t("digital.new_hello_label")}
                                placeholder="e.g. hello123"
                                name="hello"
                                autoComplete="hello"
                                value={code}
                                onChange={handleChange }
                                autoFocus
                                
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 6 ,fontWeight:'bold'}}
                            >
                                {t("digital.change") }
                            </Button>

                        </Box>
                    </Box>
                </Container>
            </ThemeProvider>
        );
    }
