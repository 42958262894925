import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import ErrorPage from "./error-page";
import { Campaign, loader as idLoader, loaderCampaign, noLoader } from "./routes/campaign";
import './i18n';

import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from './routes/home';

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home />,
        errorElement: <ErrorPage />,
    },
    {
        path: "/dm/:id",
        element: <Campaign />,
        loader: idLoader
    },
    {
        path: "/dm/",
        element: <Campaign />,
        loader: noLoader
    },
    {
        path: "/c/:cid",
        element: <Campaign />,
        loader: loaderCampaign

    }
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
        <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
