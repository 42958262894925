import * as React from 'react';
import styles from '../styles/index.module.css';
import Typography from '@mui/material/Typography';

import Box from '@mui/material/Box'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import ToggleButton from '@mui/material/ToggleButton'
//png
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import SmsIcon from '@mui/icons-material/Sms';
import TelegramIcon from '@mui/icons-material/Telegram';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import CropPortraitIcon from '@mui/icons-material/CropPortrait';
import viber from '../img/g12.png';
import { useTranslation } from 'react-i18next';


export default function Platforms(props: any) {
    const [platform, setPlatform] = React.useState('whatsapp');
    const [socialMedia, setSocialMedia] = React.useState("instagram");
    const { t, i18n } = useTranslation();

    //START Hande inputs
    const handleChange = (event: React.MouseEvent<HTMLElement>, nextView: string) => {
        setPlatform(nextView); //Set state for platform
        props.platform(nextView)    //send to campaign.tsx and changes the logo
    };

    const handleChangeSocMedia = (event: React.MouseEvent<HTMLElement>, platformSize: string) => {
        setSocialMedia(platformSize)    //set the state for social media
        props.setSocialMedia(platformSize); //send to campaign.tsx, changes the state for current social media platform selected
    };
    //END Handling input

    //BREAKPOINTS for CSS inline MUI properties:
    //mobile: 0,
    //tablet: 599,
    //desktop: 1201,
    //larger: 2500
    // [mobile,tablet,desktop,larger]

    return (
        <Box className={styles.digitalOptions} sx={{ width: ['auto', "auto", '675px'], height: ["auto", "auto", "auto", "450px"], padding: ['1.125rem'], maxHeight: ["auto", null, "42vh"], alignItems: ['center', 'flex-start'], flexWrap:['wrap'] }}>
            <Box className={styles.selector}>
                <Typography className={styles.noto} sx={{mb:1} }>
                    {t("digital.platform") }
                </Typography>
                <ToggleButtonGroup
                    orientation="vertical"
                    value={platform }
                    exclusive
                    onChange={handleChange}
                >
                    <ToggleButton value="whatsapp" aria-label="list">
                        <WhatsAppIcon /> Whatsapp
                    </ToggleButton>
                    <ToggleButton value="telegram" aria-label="module">
                        <TelegramIcon /> Telegram
                    </ToggleButton>
                    <ToggleButton value="sms" aria-label="quilt">
                        <SmsIcon /> SMS
                    </ToggleButton>
                    <ToggleButton value="viber" aria-label="quilt">
                        <img className={styles.viberLogo} src={viber } alt='viber logo' /> Viber
                    </ToggleButton>
                </ToggleButtonGroup>
            </Box>
            <Box className={styles.selector}>
                <Typography className={styles.noto} textAlign='center' sx={{ mb: 1 }} >
                    {t("digital.size") }
                </Typography>
                <ToggleButtonGroup
                    orientation="vertical"
                    value={socialMedia}
                    exclusive
                    onChange={handleChangeSocMedia}
                >
                    <ToggleButton value="instagram" aria-label="list">
                        <InstagramIcon />
                        <Typography className={styles.socialMediaTypo}>Instagram ({t("digital.square") })</Typography>
                    </ToggleButton>
                    <ToggleButton value="facebook" aria-label="module">
                        <FacebookIcon />
                        <Typography className={styles.socialMediaTypo}>Facebook ({t("digital.landscape")})</Typography>
                    </ToggleButton>
                    <ToggleButton value="story" aria-label="quilt">
                        <CropPortraitIcon />
                        <Typography className={styles.socialMediaTypo}>Story ({t("digital.vertical")})</Typography>
                    </ToggleButton>
                </ToggleButtonGroup>
            </Box>
        </Box>
    );
}