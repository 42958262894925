import * as React from 'react';
import styles from '../styles/home.module.css'
import AppBar from '@mui/material/AppBar';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import awrlogo from '../img/awrcpe.png'
import Button from '@mui/material/Button';
import { Link } from "react-router-dom";
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
const locales: any = {
    en: { title: 'English', short: "En" },
    af: { title: 'Afrikaans', short: "Af" },
    es: { title: 'Español', short: "Es" },
    fr: { title: "Français", short: "Fr" }
};

function Copyright() {
    return (
        <Typography variant="body2" color="text.secondary" align="center">
            {'Copyright '}
            <a color="inherit" href="https://awr.org/">
                AWR
            </a>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const cards = [
    { url: "https://cdedata.directus.app/assets/17b43b3f-8fd4-4868-a14b-29311213c099" },
    { url: "https://cdedata.directus.app/assets/8014e031-c7f1-46e2-b585-d8a6aa9b9031" },
    { url:"https://cdedata.directus.app/assets/620f9ae8-d79a-4599-8503-3838ba1ba807"}
    ];

const theme = createTheme({
    typography: {
        fontFamily: [
            'Noto Sans'
        ].join(',')
    },
    breakpoints: {
        values: {
            mobile: 0,
            tablet: 600,
            desktop: 1200,
            larger: 2500
            //xl: 2536
        },
    }
})

const  Home =()=> {
    const { t, i18n } = useTranslation();
    const [lang, setLang] = React.useState('en');
    //Set the language
    React.useEffect(() => {
        setLang(i18n.resolvedLanguage)
	},[])

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <AppBar position="relative" sx={{ flexGrow: 1, background: "#07477B", height: '110px',justifyContent:"center" }} className={styles.appbar }>
                <Toolbar sx={{ padding: ['0 4%', '0 6%'], width: ['auto', '100%'] }}>
                    <img src={awrlogo} className={styles.awrlogo} alt='awr logo' />
                    <Typography color="inherit" sx={{
                        paddingLeft: '4.5%', fontSize: ['small', 'larger', 'x-large', 'xx-large']
                    }} className={styles.noto}>
                        {t("main.tool") }
                    </Typography>
                    <FormControl sx={{ m: 1, minWidth: 105, ml: ["auto"], display: ["none","block"] }} size="small">
                        <Select
                            value={lang}
                            displayEmpty
                        >
                            {Object.keys(locales).map((locale) => (
                                <MenuItem value={locale} key={locale}>
                                    <Button style={{ fontWeight: i18n.resolvedLanguage === locale ? 'bold' : 'normal' }} type="submit"
                                        onClick={() => {
                                            i18n.changeLanguage(locale);
                                            setLang(locale)
                                            console.log("Locale",locale)
                                        }}
                                    >
                                        {locales[locale].title}
                                    </Button>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl sx={{ m: 1.5, minWidth: 70, display: ["block", "none"] }} size="small">
                        <Select
                            value={lang}
                            displayEmpty
                        >
                            {Object.keys(locales).map((locale) => (
                                <MenuItem value={locale} key={locale} sx={{ padding: ["7px"]} }>
                                    <Button style={{ fontWeight: i18n.resolvedLanguage === locale ? 'bold' : 'normal' }} type="submit" sx={{minWidth:"23px"} }
                                        onClick={() => {
                                            i18n.changeLanguage(locale);
                                            setLang(locale)
                                            console.log("Locale", locale)
                                        }}
                                    >
                                        {locales[locale].short}
                                    </Button>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Toolbar>
            </AppBar>
            <main>
                {/* Hero unit */}
                <ul>
                    
                </ul>
                <Box
                    sx={{
                        bgcolor: 'background.paper',
                        pt: 8,
                        pb: 6,
                    }}
                >
                    <Container maxWidth="desktop">
                        <Typography
                            component="h1"
                            variant="h2"
                            align="center"
                            color="text.primary"
                            gutterBottom
                            className={styles.noto }
                        >
                            {t("main.title")}
                        </Typography>
                        <Typography variant="h5" align="center" color="text.secondary" paragraph className={styles.noto}>
                            { t("main.subtitle")}
                        </Typography>
                        <Stack
                            sx={{ pt: 4 }}
                            direction="row"
                            spacing={2}
                            justifyContent="center"
                        >
                            <Button variant='contained' component={Link} to="/dm/0" sx={{ background:"#07477B"} }>
                                {t("main.start_button")}
                            </Button>
                        </Stack>
                    </Container>
                </Box>
                <Container sx={{ py: 6 }} maxWidth="desktop">
                    {/* End hero unit */}
                    <Grid container spacing={1}>
                        {cards.map((card,index) => (
                            <Grid item key={index} desktop={4}>
                                <Card
                                    sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                                >
                                    <CardMedia
                                        component="img"
                                        sx={{
                                            // 16:9
                                            pt: '3.25%',
                                        }}
                                        image={card.url }
                                        alt="random"
                                    />
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </main>
            {/* Footer */}
            <Box sx={{ bgcolor: 'background.paper', p: 6 }} component="footer">
                <Typography variant="h6" align="center" gutterBottom>
                    Adventist World Radio
                </Typography>
                <Typography
                    variant="subtitle1"
                    align="center"
                    color="text.secondary"
                    component="p"
                    className={styles.noto}
                >
                    All rights reserved.
                </Typography>
                <Copyright />
            </Box>
            {/* End footer */}
        </ThemeProvider>
    );
}

export default function WrappedApp() {
    return (
        <Suspense fallback="...loading">
            <Home />
        </Suspense>
    )
}