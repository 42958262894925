import react, { useRef, useEffect } from 'react'
import stylesBusiness from '../styles/business.module.css';
import styles from "../styles/index.module.css";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { useTranslation } from 'react-i18next';

import { PDFDocument, StandardFonts,rgb } from 'pdf-lib';

const BusinessCard = (props:any) => {
    const [loading, setLoading] = react.useState(false);
    const [created, setCreated] = react.useState(false);
    const [pdf, setPdf] = react.useState<Blob | null>();
    const [qrUrl, setQrUrl] = react.useState<string | null>("");
    const ref = useRef<HTMLDivElement>(null);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        //If a new image is selected, scroll to previsualizer
        if (props.image?.image)
            ref.current!.scrollIntoView({ behavior: 'smooth' });
    }, [props.image])

    useEffect(() => {
        //If a new sharing link is received from campaign.tsx it sets the URL to it, or if its emtpy (default), check if the URL already has a DM or CAMPAIGN
        //console.log("Porps", props);
        if (props.customqr) {
            setQrUrl(props.customqr);
        } else if (props.hello) { //means a DM hello code comes from URL
            setQrUrl("https://awr.org/inspire?dm=" + (props.hello !== "0" ? props.hello : ""));
        } else {
            setQrUrl("https://awr.org/inspire?c=" + (props.camp !== "0" ? props.camp : ""));
        }
        console.log("New QR URL",qrUrl)
    }, [props]);

    async function embedImages() {
        //Get images string url
        const pngFront = `https://cdedata.directus.app/assets/${props.image.image}`;
        const pngBack = 'https://cdedata.directus.app/assets/7b4f3c12-2d67-4655-9a6b-133a2c285d4f';
        const qr = props.sharinLink ? props.sharingLink : `https://chart.googleapis.com/chart?cht=qr&chs=200x200&chl=${qrUrl}&chld=Q|1`;
        const bleed = 'https://cdedata.directus.app/assets/40eeb8b2-d993-448b-931b-1825f80afc6e';

        //fetch images and transform to buffer
        const frontImageBytes = await fetch(pngFront).then((res) => res.arrayBuffer());
        const backImageBytes = await fetch(pngBack).then((res) => res.arrayBuffer());
        const qrImageBytes = await fetch(qr).then((res) => res.arrayBuffer());
        const bleedImageBytes = await fetch(bleed).then((res) => res.arrayBuffer());

        //Create pdf document
        const pdfDoc = await PDFDocument.create()

        //Create png images from buffers
        const frontImage = await pdfDoc.embedPng(frontImageBytes)
        const backImage = await pdfDoc.embedPng(backImageBytes);
        const qrImage = await pdfDoc.embedPng(qrImageBytes)
        const bleedImage = await pdfDoc.embedPng(bleedImageBytes);

        //scale images
        frontImage.scale(0.9);
        backImage.scale(0.9);
        qrImage.scale(0.9);

        //Create page 1 with size of front image and add page 2, with size of back image
        const page = pdfDoc.addPage([frontImage.width, frontImage.height]);
        const page2 = pdfDoc.insertPage(1, [backImage.width, backImage.height])

        //insert bleed image in page 1 and 2, at coordinates 0,0
        page.drawImage(bleedImage, {
            x: 0,
            y: 0,
            width: page.getWidth(),
            height: page.getHeight(),
        });
        page2.drawImage(bleedImage, {
            x: 0,
            y: 0,
            width: page2.getWidth(),
            height: page2.getHeight(),
        })
        //insert front image in page 1 at 30,50 (bleed)
        page.drawImage(frontImage, {
            x: 30,
            y: 50,
            width: frontImage.scale(0.94).width,
            height: frontImage.scale(0.85).height,
        })

        //pdfDoc.registerFontkit(fontkit);
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const timesRomanFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold)
        const size = page.getSize()
        const fontSize = 22;

        //insert back image in page 2 at 30,50 (bleed)
        page2.drawImage(backImage, {
            x: 30,
            y: 50,
            width: backImage.scale(0.94).width,
            height: backImage.scale(0.85).height,
        })
        //insert QR Image in page 2 at center bottom
        page2.drawImage(qrImage, {
            x: (page.getWidth() / 2)-(qrImage.width/2),
            y: 90,
            width: qrImage.scale(0.9).width,
            height: qrImage.scale(0.9).height,
        })
        //insert text in page 2, at middle 
        page2.drawText('Open your camera and scan the code to contact us', {
            x: size.width / 4,
            y: page2.getHeight() / 2,
            size: fontSize,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        })

        const pdfBytes = await pdfDoc.save(); //save PDF

        return pdfBytes; //return PDF
    }

    //BREAKPOINTS for CSS inline MUI properties:
    //mobile: 0,
    //tablet: 599,
    //desktop: 1201,
    //larger: 2500
    // [mobile,tablet,desktop,larger]
	return (
        <Box
            sx={{
                padding: [null, "2.25rem 0"], justifyContent: ['center', 'center'],
                display: ["block", 'flex'], margin: ['0 auto 1.75rem', 0], width: ["auto", `${props.image?.image ? "65%" : "45%"}`, `${props.image?.image ? "75%" : "50%"}`, `${props.image?.image ? "70%" : "70%"}`], height: [`${props.image?.image ? "auto" : "24vh"}`, `${props.image?.image ? "auto" : "76vh"}`]
            }}
            className={styles.imageBox}
            
        >
            {props.image ? <Box className={styles.boxForImage} sx={{ height: ["auto", null, 'auto', '75%'] }} ref={ref}>
                {props.image?.image ? <Box sx={{
                    display: [null, null, 'flex'], flexDirection: 'column', justifyContent: 'center',
                    marginTop: [null, null, null, '4rem'], padding: [null,null,'3%',null]
                }}> <img
                        alt="to download"
                        src={`https://cdedata.directus.app/assets/${props.image?.image}`}
                        width='100%'
                        height='100%'
                />
                    <img
                    alt="second page of pdf"
                        src={`https://cdedata.directus.app/assets/7b4f3c12-2d67-4655-9a6b-133a2c285d4f`}
                        width='100%'
                        height='100%' />
                </Box>
                    : false
				}
                <Box className={stylesBusiness.textOnImage} sx={{ marginTop: ['-8rem',"-7.1rem", "-11rem",'-18rem'] , flexDirection:['column'],display:'flex'}}>
                    <Typography variant="h6" className={stylesBusiness.helloParagraph} sx={{fontSize:['small',"small",'inherit','x-large']} }>
                        Open your camera and scan the code
                    </Typography>
                    <Box sx={{ width: ['80px', "59px", '85px', '105px'] }}>
                        <img alt='qr' width='100%'
                            src={`https://chart.googleapis.com/chart?cht=qr&chs=200x200&chl=${qrUrl}&chld=Q|1`}
                        />
                    </Box>
                </Box>
                
            </Box> : <Stack sx={{ width: ['100%', '90%'], padding: "2rem",margin:'auto' }} spacing={2}>
                    <Alert severity="info" sx={{ justifyContent: 'center', textAlign: 'center' }}>
                        <Typography> {t("card.alert")} <Link underline="hover" variant="inherit" color="#321110" sx={{ cursor: 'pointer' }}
                            onClick={props.scrollToDesign}>{ t("card.alert_cta")}</Link></Typography>
                    </Alert>
            </Stack>}
            {props.image ? <Button variant="outlined" sx={{ margin: ["1.25rem auto", "3.25rem auto"], display: 'flex' }} onClick={ async() => {
                setLoading(true)
                const response = await embedImages();
                setLoading(false)
                //console.log("response",response)
                const filesArray: File[] = [new File([response], 'CPE-personal-card.pdf', { type: "application/pdf", lastModified: new Date().getTime() })];
                const content = new Blob([response], { type: "application/pdf" });

                setPdf(content);
                setCreated(true);
                const shareData = {
                    files: filesArray,
                };
                if (navigator.canShare(shareData)) {
                    try {
                        await navigator.share({
                            files: filesArray,
                            title: "Your CPE personal card",
                            text: "Join with your smartphone",
                        });
                        console.log("Shared")
                    } catch (error: any) {
                        console.log(error.message)
                    }
                } else {
                    console.log(`Your system doesn't support sharing these files.`);
                }
            }
            }
                disabled={loading}
            >
                {loading ? 'Creating card':'share personal card as PDF' }
            </Button> : false}
            {created ? <Button variant='outlined' sx={{display:'flex',margin:'0 auto'}} onClick={async () => {

                const encodedUri = window.URL.createObjectURL(pdf!);
                const link = document.createElement("a");

                link.setAttribute("href", encodedUri);
                link.setAttribute("download", 'cpe-card');

                link.click();
			    }
            }>
                download
            </Button> : null}
        </Box>
		)
}

export default BusinessCard