import react, { useState,useRef, useEffect } from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import styles from "../styles/index.module.css";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import CircularProgress from '@mui/material/CircularProgress';
import ShareIcon from '@mui/icons-material/Share';

//Import PNG logos
import whatsapp from '../logos/whatsapp.png';
import telegram from '../logos/telegram.png';
import sms from '../logos/sms.png';
import viber from '../logos/viber.png';
import { useTranslation } from 'react-i18next';

const DigitalCard = (props: any) => {
    //console.log("props", props)
    const [urlPlatformSelected, setUrlPlatformSelected] = useState(props.platform);
    const [number, setNumber] = useState<string | number>();
    const [currentFile, setCurrentFile] = useState<string>("");
    const [loading, setLoading] = react.useState(true);
    const ref = useRef<HTMLDivElement>(null);
    const { t, i18n } = useTranslation();

    /*START UseEffect*/

    useEffect(() => {
        //if the image selected changes, scrolls to the previsualizer
        if (props.image?.image)
            ref.current!.scrollIntoView({ behavior: 'smooth' });
    }, [props.image]);

    useEffect(() => {
        //set the state every time the platform changes from campaign.tsx (and platforms.tsx)
        let number: number | string;
        switch (props.platform) {
            case "whatsapp":
                setUrlPlatformSelected(whatsapp);
                number = "+1 949 860 7007"; break;
            case "telegram":
                setUrlPlatformSelected(telegram);
                number = "@R360BOT"; break;
            case "sms":
                setUrlPlatformSelected(sms);
                number = "+1 949 860 7007"; break;
            case "viber":
                setUrlPlatformSelected(viber);
                number = "Inspire360Now"; break;
            default:
                setUrlPlatformSelected(whatsapp);
                number = "+1 949 860 7007"; break;
        }
        console.log("Number?", number);
        setNumber(number);
    }, [props.platform]);

    useEffect(() => {
        //Start creating the customized image every time the image, platform or custom QR code changes
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "imageId": props.image?.image,
            "logo": props.platform,
            "hellocode": props.code ? props.code : "hello",
            "socialMedia": props.image?.socialmedia,
            "language":props.language
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw
        };
        if (props.image) {
            try {
                fetch("https://us-central1-cpe-marketing-tool.cloudfunctions.net/cardApi/api/convert", requestOptions)
                    .then(response => response.json(), (error) => { console.log("Select an image!", error) })
                    .then(result => {
                        console.log("Result", result);
                        if (result.image != null)
                            setCurrentFile(result.image)
                        else
                            throw new Error("Error generating image")
                        setLoading(false)
                    }).catch(e => console.log("Error fetch result",e))
            }
            catch (e) {
                console.log("Error try catch",e)
			}
        }
    }, [props.image, props.platform, props.code]) //from postman

    //BREAKPOINTS for CSS inline MUI properties:
    //mobile: 0,
    //tablet: 599,
    //desktop: 1201,
    //larger: 2500
    // [mobile,tablet,desktop,larger]
    return (
        <Box
            className={styles.imageBox}
            sx={{
                padding: [null, "2.25rem 0"], justifyContent: ['center', 'flex-start'],
                display: ["inline-flex", 'flex'], margin: ['0 auto 1.75rem', 0], width: [`${props.image?.image ? "-webkit-fill-available" : "auto"}`, `${props.image?.image ? "75%" : "75%"}`], height: [`${props.image?.image ? "auto" : "24vh"}`, `${props.image?.image ? "auto" : "76vh"}`]
                }}
            ref={ref}
        >
            {/*If a background image is selected, show box with image, if not, show alert*/ }
            {props.image ? <Box className={styles.boxForImage}
                            sx={{
                                aspectRatio: props.socialMedia.ratio, maxWidth: ['100%', '75%', '90%', `${props.socialMedia.max}%`]
                            }}
                        >
                 {props.image?.image ? <img
                        alt="to download"
                        src={`https://cdedata.directus.app/assets/${props.image?.image}`}
                        width='100%'
                        height='100%'
                    /> : false
                }
                <Box className={styles.textOnImage} sx={{ marginTop: [props.socialMedia.mobileTop, props.socialMedia.mobileTop+1, props.socialMedia.lgTop, props.socialMedia.top] }}>
                        <Box className={styles.logoContainer} sx={{ maxHeight: ['20px', "25px", '25px', '30px'], width: ["auto"], height: '100%' }}>
                            <img className={styles.platformLogo} src={urlPlatformSelected} alt="platform logo" />
                        </Box>
                        <Typography className={styles.helloParagraph} sx={{ fontSize: ['smaller', "small", props.socialMedia.textSize, 'x-large'] , marginLeft:'.3rem'} }>
                            {t("text")} {props.code ? props.code : "hello" } {t("to")} {number}
                        </Typography>
                    </Box>
            </Box> :
                <Stack sx={{ width: ['100%', '95%'], padding: "2rem", margin: 'auto' }} spacing={2}>
                    <Alert severity="info" sx={{ justifyContent: 'center', textAlign:'center'} }>
                        <Typography> {t("digital.alert") } <Link underline="hover" variant="inherit" color="#321110" sx={{ cursor: 'pointer' }}
                            onClick={() => props.select()}>{t("digital.alert_cta") }</Link></Typography>
                            </Alert>
                </Stack>}
            {props.image ?
                //"Share image" button, on click loads the browsers SHARE API, but is disabled until the image is received from backend
                <Button variant="outlined" sx={{ margin: "1.25rem auto" }} className={styles.shareButtons} onClick={
                    async () => {

                        const response = await fetch(currentFile);
                        const blob = await response.blob();
                        const filesArray: File[] = [new File([blob], 'cpe.jpg', { type: "image/png", lastModified: new Date().getTime() })];
                        const shareData = {
                            files: filesArray,
                        };
                        if (navigator.canShare(shareData)) {
                            try {
                                if (navigator.platform)
                                    await navigator.share({
                                        files: filesArray
                                    });
                                console.log("Shared")
                            } catch (error: any) {
                                console.log(error.message)
                            }
                        } else {
                            console.log(`Your system doesn't support sharing these files.`);
                        }
                    }
                }
                disabled={loading } //Its disabled until the image is received from backend. It is being created since selected (check useEffect)
                >
                    {loading ? //show circular progress element if image not received yet, then its share icon
                        <CircularProgress size={24} sx={{ display: loading ? "block" : "none", padding: ".2rem auto" }} /> : <ShareIcon />
                    }
                    <Typography ml={2}>{loading ? t("digital.preparing") :t("digital.share") }</Typography>
                </Button>
                : false}
            
            {loading ?
                //show download Button once the image create is received
                false : <Button variant='outlined' sx={{ margin: '0 auto' }} className={styles.shareButtons} onClick={
                    async () => {

                        fetch(currentFile, { method: 'GET' }).then(result => result.arrayBuffer())
                            .then((response) => {
                                console.log("responses",response)
                                const url = window.URL
                                    .createObjectURL(new Blob([response]));
                                const link = document.createElement('a');
                                link.href = url;
                                link.setAttribute('download', 'CPE-poster.png');
                                document.body.appendChild(link);
                                link.click();
                            })

                    }
                }>
                    {t("digital.download") }
            </Button>}
        </Box>
		)
}

export default DigitalCard


