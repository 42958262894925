import * as React from 'react'
import styles from '../styles/index.module.css';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';


export default function Platforms(props: any) {
    //console.log("BACKGROPUND PROPS",props)
    const imgArray: Array<any> = props.images;
    const { t, i18n } = useTranslation();


    const handleImageSelected = (image: string) => {
        props.setImageSelected(image);//sends the new image to campaign.tsx
	}

    //BREAKPOINTS for CSS inline MUI properties:
    //mobile: 0,
    //tablet: 599,
    //desktop: 1201,
    //larger: 2500
    // [mobile,tablet,desktop,larger]
	return (
        <ImageList sx={{ width: ['auto', "auto", '675px'], height: ["auto", "auto","425px"], padding: ['1.125rem'] }} className={styles.miniaturesContainer} gap={13}>
            {imgArray[0] ? imgArray.map((image: any) => (
                <ImageListItem key={image.id}>
                    <img
                        src={`https://cdedata.directus.app/assets/${image.image}?w=144&h=144&fit=crop&auto=format`}
                        srcSet={`https://cdedata.directus.app/assets/${image.image}?w=144&h=144&fit=crop&auto=format 2x`}
                        alt={image.title}
                        loading="lazy"
                        onClick={() => {
                            handleImageSelected(image);
                        }}
                    />
                </ImageListItem>
            )) : 
                <Alert severity="info" sx={{ justifyContent: 'center', textAlign: 'center' }}>
                    <Typography> {t("digital.no_lang")}</Typography>
                </Alert>
        }
        </ImageList>
		)
}