import styles from "../styles/index.module.css";
import { useState, useEffect } from 'react';
import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
//
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Slide from '@mui/material/Slide';
//Import PNG logos
import bannerlogo from '../img/awrcpe.png'
import awrlogo from '../logos/awrlogo.svg'
import DigitalCard from '../components/digitalCard';
import BusinessCard from '../components/businessCard';
import {useLoaderData } from "react-router-dom";
import Platforms from "../components/platforms";
import Background from "../components/background";
import HelloChanger from "../components/helloChanger";
//Languages
import { useTranslation } from 'react-i18next';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
const locales: any = {
    en: { title: 'English', short: "En" },
    af: { title: 'Afrikaans', short: "Af" },
    es: { title: 'Español', short: "Es" },
    fr: { title:"Français",short:"Fr"}
};

//Loaders that read URL parameters
async function loader({ params }:any) {
    const dmid = params.id;
    return { dmid };
}

async function loaderCampaign({ params }: any) {
    const campaign = params.cid;
    return { campaign };
}

async function noLoader({ params }: any) {
    console.log("Example. No DM ID or Campaign ID");
}
//END LOADERS

//MUI Modules
declare module '@mui/material/styles' {
    interface BreakpointOverrides {
        xs: false; // removes the `xs` breakpoint
        sm: false;
        md: false;
        lg: false;
        xl: false;
        mobile: true;
        tablet: true;
        desktop: true;
        larger: true;
    }
}

    //Theme for font family and breakpoints
const theme = createTheme({
    typography: {
        fontFamily: [
            'Noto Sans'
        ].join(',')
    },
    breakpoints: {
        values: {
            mobile: 0,
            tablet: 799,
            desktop: 1201,
            larger: 1920
            //xl: 2536
        },
        },
});
//END MUI Modules

const Campaign: React.FC = (props: any) => {
    const { dmid }: any = useLoaderData();
    const { campaign }: any = useLoaderData();

    const [campaignReceived, setCampaignReceived] = useState<string | null>(null)
    const [showDigital, setShowDigital] = useState(true);
    const [imgArray, setImgArray] = useState([]);
    const [imageSelected, setImageSelected] = useState<any | null>(null);
    const [view, setView] = React.useState('whatsapp');
    const [socialMedia, setSocialMedia] = useState('instagram');
    const [imgSize, setImgSize] = useState<any>({})
    const [sharingLink, setSharingLink] = useState<string | null>(null);
    const refCard = React.useRef<HTMLDivElement>(null);
    const refDigital = React.useRef<HTMLDivElement>(null);
    const { t, i18n } = useTranslation();
    const [lang, setLang] = React.useState<string|undefined>(undefined);
     
    /* MUI TABS*/
    const [value, setValue] = React.useState(0);

    const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
        setImgArray([]);
        setSocialMedia("instagram");
        setView('whatsapp')
        setValue(newValue);
        switch (newValue) {
            case 0:
                setImageSelected(null)
                setShowDigital(true); break;
            case 1:
                setImageSelected(null)
                setShowDigital(false);
                setSocialMedia("business"); break;
            default:
                console.log("Default")
        }
    };

    interface LinkTabProps {
        label?: string;
        href?: string;
    }

    function LinkTab(props: LinkTabProps) {
        return (
            <Tab
                component="a"
                onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                    event.preventDefault();
                }}
                {...props}
            />
        );
    }

    /*END TABS*/

    /* START USE EFFECTS*/
    //Set the language
    React.useEffect(() => {
        setLang(i18n.resolvedLanguage)
    }, [])

    //read parameter set on loader, and set state with inspire URL
    React.useEffect(() => {
        if (dmid) {
            setSharingLink("https://awr.org/inspire?dm=" + (dmid !== "0" ? dmid : ""))
        } else if (campaign) {
            setSharingLink("https://awr.org/inspire?c=" + (campaign !== "0" ? campaign : ""))
        } else {
            setSharingLink("https://awr.org/inspire")
        }
    }, []);

    //request to AWR Orio API with method and id for getting Hello CODE        
    React.useEffect(() => {
        //console.log(campaign,dmid)
        // Code using query
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "cmd": dmid ? "getEvangelistContactCode" : "getCampaignContactCode", //methods from API
            "token": "4UzUFgaJ;f87{X+TjSSD=3dvS[YpZG", //token (doesnt change)
            "id": dmid, //id if exists
            "campaignId":campaign //campaignId if exists
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
        };
        fetch("https://awrchatbot.org/orion/appapi.php", requestOptions)
            .then(response => response.json()).then((result) => {
                console.log("campaign received", result)
                setCampaignReceived(result.contactCode ? result.contactCode : (result.codes ? result.codes[0] : "hello")) //If contact code, means the URL is of a DM, if result.codes, means is a campaign
            })
    }, []);


    useEffect(() => {
        //main info for img sizes in the previsualization. Runs every change of social media platform
        switch (socialMedia) {
            case "facebook":
                setImgSize({ width: 1920, height: 1080, ratio: "1.77777777778", textSize: 'large', top: "-5.25rem", max: 95, lgTop: '-3.5rem', mobileTop: "-4.5rem" }); break;
            case "instagram":
                setImgSize({ width: 1080, height: 1080, ratio: "1", textSize: 'large', top: "-6.25rem", max: 60,lgTop:'-5.25rem', mobileTop: "-4.75rem", boxHeight: "74vh" }); break;
            case "story":
                setImgSize({ width: 1080, height: 1920, ratio: "0.5625",textSize:'x-large', top: "-8rem", max: 45, lgTop: '-9rem', mobileTop: "-5.75rem", boxHeight: "74vh" }); break;
            case "business":
                setImgSize({ width: 1050, height: 600, ratio: "1.75", bottom: "0.5rem" }); break;
            default:
                setImgSize({ width: 1920, height: 1080 }); break;
        }
    }, [socialMedia])

    useEffect(() => {
        //GET LIST OF BACKGROUND IMAGES FROM DIRECTUS. Runs every state change of social media platform, from platforms.tsx
        setImageSelected(null); //delete current image selected in the state
        var requestOptions = {
            method: 'GET'
        };
        fetch("https://cdedata.directus.app/items/marketing_cpe?filter[socialmedia][_eq]=" + socialMedia +"&filter[lang][_eq]="+lang, requestOptions)
            .then(response => response.json())
            .then(result => {
                //console.log("Lang",lang)
                //console.log("Result",result)
                setImgArray(result.data);
            })
            .catch(error => console.log('error', error));
    }, [socialMedia,lang])

    /* END USE EFFECT */
    //Hide top bar on scroll down
    function HideOnScroll(props: any) {
        const { children, window } = props;
        // Note that you normally won't need to set the window ref as useScrollTrigger
        // will default to window.
        // This is only being set here because the demo is in an iframe.
        const trigger = useScrollTrigger({
            target: window ? window() : undefined,
        });

        return (
            <Slide appear={false} direction="down" in={!trigger}>
                {children}
            </Slide>
        );
    }
    //END Hide on scroll

    /*Steps for customizing the image (From MUI DOCS)*/
    const steps = [t("digital.steps.one"), t("digital.steps.two"), t("digital.steps.three")];
    const [activeStep, setActiveStep] = React.useState(0);
    function getStepContent(step: number) {
        switch (step) {
            case 0:
                return <Platforms platform={(e: any) => setView(e)} setSocialMedia={(e:any) => {setSocialMedia(e) } }/>;
            case 1:
                return <Background key={socialMedia} images={imgArray} setImageSelected={(e: any) => { setImageSelected(e) }} />;
            case 2:
                return <HelloChanger code={campaignReceived} changer={(hellocode:any) => {setCampaignReceived(hellocode) } } />;
            default:
                throw new Error('Unknown step');
        }
    }

    const handleNext = () => {
        setActiveStep(activeStep + 1);
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    /*End methods steps*/

    /*PDF Business card CHange QR*/
    const handleNewQr = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const qr: any = new FormData(event.currentTarget).get('qr');
        console.log(qr);
        setSharingLink(qr); //changes the state with new inspire URL, this reloads the businessCard component
	}
    /*END PDF Change QR*/
    /*Start Language*/
    React.useEffect(() => {
        setLang(i18n.resolvedLanguage)
    }, [])
    /*END Language*/
    return (
        <>
            <header>
                <title>CPE - Customize your ad image</title>
                <meta name="description" content="Diego Fuentes DM tool" />
                <link rel="icon" href="https://cde.awr.org/wp-content/uploads/2020/02/cropped-LOGO-CDE-AWR360-192x192.png" sizes="192x192"></link>
            </header>
            <HideOnScroll {...props}>
                <Box sx={{ flexGrow: 1, position: 'fixed', top: 0, width: '100%' }}>
                    <AppBar position="sticky" sx={{ background: "#07477B", boxShadow: 'none' }}>
                        <Toolbar sx={{px:1} }>
                            <img src={bannerlogo} alt='banner logo CpE' className={styles.bannerlogo} />
                            <Typography className={styles.noto} component="p" sx={{ flexGrow: 1, fontSize: ['small', "medium", 'smaller', 'x-large'], textAlign: ['center',"center","center","left"] }}>
                                {t("banner") }
                            </Typography>
                            <FormControl sx={{ m: 1, minWidth: 120,display:["none","block"] }} size="small">
                                {/*<InputLabel id="demo-select-small">{lang}</InputLabel>*/}
                                <Select
                                    value={lang}

                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                >
                                    {Object.keys(locales).map((locale) => (
                                        <MenuItem value={locale} key={locale}>
                                            <Button style={{ fontWeight: i18n.resolvedLanguage === locale ? 'bold' : 'normal' }} type="submit"
                                                onClick={() => {
                                                    i18n.changeLanguage(locale);
                                                    setLang(locale)
                                                }}
                                            >
                                                {locales[locale].title}
                                            </Button>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl sx={{ m: 1.5, minWidth: 70, display: ["block","none"]}} size="small">
                                {/*<InputLabel id="demo-select-small">{lang}</InputLabel>*/}
                                <Select
                                    value={lang}
                                    displayEmpty
                                >
                                    {Object.keys(locales).map((locale) => (
                                        <MenuItem value={locale} key={locale} sx={{ padding: ["7px"] }}>
                                            <Button style={{ fontWeight: i18n.resolvedLanguage === locale ? 'bold' : 'normal' }} type="submit" sx={{ minWidth: "23px" }}
                                                onClick={() => {
                                                    i18n.changeLanguage(locale);
                                                    setLang(locale)
                                                    console.log("Locale", locale)
                                                }}
                                            >
                                                {locales[locale].short}
                                            </Button>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Toolbar>
                    </AppBar>
                </Box>
            </HideOnScroll>
            <Box component="main" className={styles.main} sx={{ padding: ["3% 2% 0rem", "2.75% 13.75% 0rem"] }}>
                <ThemeProvider theme={theme}>
                    <Box className={styles.mainBox} sx={{
                        height: '100%', display: 'flex', justifyContent: "center", alignItems: "center", flexDirection: ['column-reverse', 'column-reverse', ,'row'],
                        paddingBottom: ['1.25rem']
                    }} >
                        <CssBaseline />
                        {/* Previsualization columns*/ }
                        {showDigital ?
                            <DigitalCard image={imageSelected ? imageSelected : false} platform={view} socialMedia={imgSize} code={campaignReceived}
                            language={lang}    
                            select={() => { setActiveStep(1); refDigital!.current!.scrollIntoView({behavior:'smooth'}) } }/> //reloads on change of state
                            :
                            <BusinessCard image={imageSelected ? imageSelected : false} hello={dmid} camp={campaign} customqr={sharingLink} scrollToDesign={() => refCard.current!.scrollIntoView({ behavior: 'smooth' }) } /> //reloads on change of state
                        }

                        {/*Form column*/ }
                        <Box sx={{
                            display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', marginBottom: ["4rem", "8rem"],
                            marginTop: ["5rem", null]
                            }} className={styles.rightColumnGrid}>
                            <Box className={styles.rightColumn}>
                                <Tabs value={value} onChange={handleChangeTab} aria-label="nav tabs example" variant="scrollable" scrollButtons
                                    allowScrollButtonsMobile
                                    sx={{
                                        width: ["90%", "75%"],
                                        margin: "0 auto"
                                    }} className={styles.tabs}>
                                    <LinkTab label={t("label.digital")!} />
                                    <LinkTab label={t("label.card")! } />
                                </Tabs>
                                {/*Stepper here IF DIGITAL (default state is true) */}
                                {showDigital ? 
                                    <Container component="main" sx={{ mb: 4 }} ref={refDigital }>
                                        <Paper variant="outlined" sx={{ p: '10.15px 8px', borderRadius: '20px', backgroundColor:'#f8f9f9'}}>
                                                <Typography component="h4" variant="h4" align="center" sx={{py: [".25rem",null,"1rem"] } }>
                                                {t("digital.customize") }
                                                </Typography>
                                                <Stepper alternativeLabel activeStep={activeStep} sx={{ py: 3, pb: 5 }}>
                                                    {steps.map((label) => (
                                                        <Step key={label}>
                                                            <StepLabel>{label}</StepLabel>
                                                        </Step>
                                                    ))}
                                                </Stepper>
                                                {activeStep === steps.length ? (null) : (
                                                    <React.Fragment>
                                                    {getStepContent(activeStep)}
                                                    <Typography display={'flex'} flexDirection={'column'} textAlign={'center'}>
                                                        {t("digital.selected") } <span className={styles.selected}> {view} - {socialMedia}</span>
                                                    </Typography>

                                                    {/* BACK AND NEXT BUTTONS */ }
                                                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                            {activeStep !== 0 && (
                                                                <Button variant="outlined" onClick={handleBack} sx={{ m:"1rem"}}>
                                                                {t("digital.button_back") }
                                                                </Button>
                                                            )}
                                                            {activeStep === steps.length - 1 ? null : 
                                                                <Button
                                                                    variant="contained"
                                                                    onClick={handleNext}
                                                                    sx={{ m: "1rem", backgroundColor:"#07477B" }}
                                                            >{ t("digital.button_next")}</Button>
                                                            }
                                                        </Box>
                                                    </React.Fragment>
                                                )}
                                            </Paper>
                                    </Container>
                                    /* PRINTABLE PDF FORM AND IMAGE SELECTOR*/
                                    : <Paper className={"paper-pdf"} variant='outlined' sx={{
                                        width: ['auto', "515px", '675px'], p: '10.15px 8px', borderRadius: '20px', backgroundColor: '#f8f9f9'
                                    }} ref={refCard } >
                                        <CssBaseline />
                                        <Typography component="h4" variant="h4" align="center" sx={{ py: ["1.45rem", null, "1.1rem"] }}>
                                            {t("card.design") }
                                        </Typography>
                                        {/* Image miniatures*/ }
                                        <Box sx={{ m: '8px 11px auto' }} className={styles.miniaturesContainer}>
                                            {imgArray.map((image: any) => {
                                                return <Box
                                                    sx={{ maxWidth: "100%", width: ['165px',"181px", '222px'], margin: '.5rem' }}
                                                    key={image.id}>
                                                    <img
                                                        src={`https://cdedata.directus.app/assets/${image.image}`}
                                                        className={styles.miniatureImage}
                                                        onClick={() => {
                                                            setImageSelected(image);
                                                        }}
                                                        alt='miniatures'
                                                    />

                                                </Box>

                                            })}
                                        </Box>
                                        {/* Form for change the inspire url*/ }
                                        <Box
                                            sx={{
                                                marginTop: 8,
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Typography component="h1" variant="h5">
                                                {t("card.change") }
                                            </Typography>
                                            <Box component="form" onSubmit={handleNewQr} noValidate sx={{ mt: 1 }}>
                                                <TextField
                                                margin="normal"
                                                fullWidth
                                                id="qr"
                                                    label={t("card.change_label") }
                                                placeholder="e.g. https://awr.org/inspire/?dm=1429"
                                                name="qr"
                                                autoComplete="qr"
                                                />
                                                <Button
                                                    type="submit"
                                                    fullWidth
                                                    variant="contained"
                                                    sx={{ mt: 3, mb: 2 }}
                                                >
                                                    {t("card.change_button") }
                                                </Button>

                                            </Box>
                                        </Box>
                                    </Paper>
                                }
                              
                            </Box>
                        </Box>
                    </Box>
                </ThemeProvider>
            </Box>
            <footer className={styles.footer}>
                <Box sx={{ width: '100%', padding: 0, margin: '0 auto' }}>
                    <Box sx={{ bgcolor: 'whitesmoke', height: '93px', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                        <img
                            src={awrlogo}
                            height={145}
                            width={145}
                            className={styles.imagefooter}
                            alt="awr logo"
                        />
                        <Typography fontSize={["small", "medium"]} className={styles.noto}>
                            2023 Adventist World Radio.<br />
                            All rights reserved.<br />
                        </Typography>
                    </Box>
                </Box>
            </footer>
        </>
    );
};

export { Campaign, loader, loaderCampaign, noLoader };
/*
  Create your cell phone evangelism customized sharing resource for social media or printed sharing cards here
 */